.details-container {
    width: 100%;
    height: 100%;
    /*background: black;*/
}
.details-bg {
    z-index: 1;
    opacity: 0.1;
    color: #e62a20;
    /*color: white;*/
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    width: 100%;
}

.details-bg-title {
    font-size: 13vw;
    text-align: center;
    font-weight: bold;
    width: 100%;
}

.details-bg-meta {
    font-size: 3vw;
    text-align: center;
    font-weight: bold;
    width: 100%;
    padding-bottom: 3vw;
}
.details-bg-meta span{
   padding: 1vw;
}

.details-qrcode {
    position: absolute;
    bottom: 10px;
    right: 20px;
    z-index: 2;
    width: 120px;
    height: auto;
}

.details-half-A, .details-half-B{
    width: 50%;
    display: inline-block;
    box-sizing: border-box;
    padding: 20px;
    height: 900px;
    color: #e62a20;
    /*overflow: hidden;*/
    position: absolute;
    top: 0;
}
.details-half-A{
    left: 0;
}
.details-half-B{
   right: 0;
}
.details-meta{
    color: #e62a20;
    font-size: 24px;
    padding: 20px;
    width: 80%;
    position: absolute;
    bottom:0;
    left: 0;
    opacity: 0.9;
}
.details-meta > div{
    width: 25%;
    display: inline-block;
}
.details-meta > div > span:first-child{
    padding-right:5px;
    font-weight: bold;
}
.details-meta > div > span:last-child{
    font-weight: bold;
}
