body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: sans-serif;
  overflow:hidden;
  -webkit-overflow-scrolling: touch;
}

* {
  -webkit-overflow-scrolling: touch;
}
