body, html {
  width:100%;
  height:100%;
  /*background-color:black !important;*/
  overflow:hidden;
}
body{
  /*max-width:640px !important;*/
  margin:0 auto !important;
  /*max-height: 800px !important;*/
  position:relative;
}

.footer{
  position:absolute;
  bottom:0;
  width: 100%;
  text-align: center;
  height: 50px;
  overflow:hidden;
}
.header{
  font-size:20px;
  color:white;
  line-height:50px;
  background:black;
  text-align: center;
  height: 50px;
}

#root{
  width:100%;
  height:100%;
}
.loading-icon svg{
  fill: #e62a20;
}

.loading-icon{
  font-size:80px;
  color:#e62a20;
}

.center{
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%, -50%);
}

.center .text{
  color:#e62a20;
}

.danger{
  color:#e62a20;
}

p.line {
  min-height: 13px;
  margin: 0;
}



