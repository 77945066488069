body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: sans-serif;
  overflow:hidden;
  -webkit-overflow-scrolling: touch;
}

* {
  -webkit-overflow-scrolling: touch;
}

body, html {
  width:100%;
  height:100%;
  /*background-color:black !important;*/
  overflow:hidden;
}
body{
  /*max-width:640px !important;*/
  margin:0 auto !important;
  /*max-height: 800px !important;*/
  position:relative;
}

.footer{
  position:absolute;
  bottom:0;
  width: 100%;
  text-align: center;
  height: 50px;
  overflow:hidden;
}
.header{
  font-size:20px;
  color:white;
  line-height:50px;
  background:black;
  text-align: center;
  height: 50px;
}

#root{
  width:100%;
  height:100%;
}
.loading-icon svg{
  fill: #e62a20;
}

.loading-icon{
  font-size:80px;
  color:#e62a20;
}

.center{
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%, -50%);
}

.center .text{
  color:#e62a20;
}

.danger{
  color:#e62a20;
}

p.line {
  min-height: 13px;
  margin: 0;
}




.post-box-container {
    position: absolute;
    width: 45%;
    max-width: 400px;
    word-break: break-all;
    background: white;
    font-size: 36px;
    padding: 10px;
    color: #e62a20;
    border: 2px solid #e62a20;
    transition: top 2s cubic-bezier(0, 0, 0.2, 1), left 2s cubic-bezier(0, 0, 0.2, 1), width 1s cubic-bezier(0, 0, 0.2, 1);
    transform: translate(-50%, -50%);
}

/*.post-box-container-active {*/
    /*box-shadow: rgba(230, 42, 32, 0.8) 1px 3px 10px;*/
    /*z-index: 100000;*/
/*}*/

.post-box-header {
    height: 40px;
    line-height: 40px;
    font-size: 20px;
    width: 100%;
}

.post-box-head-image {
    background-size: 40px 40px;
    background-repeat: no-repeat;
    background-position: top left;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 20px;
    display: inline-block;
    background-color: #e62a20;
    vertical-align: top;
}

.post-box-nickname {
    vertical-align: top;
    line-height: 40px;
    display: inline-block;
    padding-left: 6px;
    max-width: calc(100% - 100px);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.post-box-content {
    padding: 20px 0;
}

.post-box-footer {
    text-align: right;
}

.post-box-point {
    display: inline-block;
    padding-left: 29px;
    margin-right: 10px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left center;
    height: 20px;
    line-height: 20px;
    font-size: 16px;
    font-weight: lighter;
}
.post-box-comment {
    display: inline-block;
    padding-left: 25px;
    margin-right: 10px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left center;
    height: 20px;
    font-size: 16px;
    line-height: 20px;
    font-weight: lighter;
}

.post-box-qrcode {
    width: 60px;
    height: 60px;
    position: absolute;
    cursor: pointer;
    top: 10px;
    right: 10px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.home-posts-container {
    width: 100%;
    height: 100%;
}

.posts-content-container {
    width: 100%;
    height: 100%;
    overflow: auto;
}
.posts-content-container:after{
    content: '';
    display: block;
    width:100%;
    height: 100px;
}

.home-posts-refresh {
    position: absolute;
    top: 10px;
    left: 20px;
    width: 20px;
    height: 20px;
    z-index: 1000000;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.home-posts-header {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    z-index: 1000000;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.home-posts-back {
    position: absolute;
    top: 10px;
    left: 20px;
    width: 24px;
    height: 20px;
    z-index: 1000000;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.home-posts-home {
    position: absolute;
    top: 8px;
    left: 55px;
    width: 24px;
    height: 24px;
    z-index: 1000000;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.details-row {
    display: block;
    box-sizing: border-box;
    position: relative;
    font-size: 14px;
    line-height: 24px;
    padding: 10px 0;
    border-bottom: solid 1px rgba(230, 42, 32, 0.42);
    font-weight: bold;
    transition: opacity linear 1s;
}
.details-row:first-child{
    border-top: solid 1px rgba(230, 42, 32, 0.42);
}
.details-row > div{
    vertical-align: top;
    display: inline-block;
}

.details-row:nth-child(even) {
    background: #ffffff1c;
}
.details-row:nth-child(odd) {
    background: #e62a201c;
}

.details-row > .details-row-user {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 30%;
    box-sizing: border-box;
    padding-left: 3px;
}
.details-row > .details-row-content {
    padding-left:30px;
    box-sizing: border-box;
    /*text-overflow: ellipsis;*/
    /*white-space: nowrap;*/
    overflow: hidden;
    width: 70%;
}

.details-container {
    width: 100%;
    height: 100%;
    /*background: black;*/
}
.details-bg {
    z-index: 1;
    opacity: 0.1;
    color: #e62a20;
    /*color: white;*/
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    width: 100%;
}

.details-bg-title {
    font-size: 13vw;
    text-align: center;
    font-weight: bold;
    width: 100%;
}

.details-bg-meta {
    font-size: 3vw;
    text-align: center;
    font-weight: bold;
    width: 100%;
    padding-bottom: 3vw;
}
.details-bg-meta span{
   padding: 1vw;
}

.details-qrcode {
    position: absolute;
    bottom: 10px;
    right: 20px;
    z-index: 2;
    width: 120px;
    height: auto;
}

.details-half-A, .details-half-B{
    width: 50%;
    display: inline-block;
    box-sizing: border-box;
    padding: 20px;
    height: 900px;
    color: #e62a20;
    /*overflow: hidden;*/
    position: absolute;
    top: 0;
}
.details-half-A{
    left: 0;
}
.details-half-B{
   right: 0;
}
.details-meta{
    color: #e62a20;
    font-size: 24px;
    padding: 20px;
    width: 80%;
    position: absolute;
    bottom:0;
    left: 0;
    opacity: 0.9;
}
.details-meta > div{
    width: 25%;
    display: inline-block;
}
.details-meta > div > span:first-child{
    padding-right:5px;
    font-weight: bold;
}
.details-meta > div > span:last-child{
    font-weight: bold;
}

