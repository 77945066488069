.details-row {
    display: block;
    box-sizing: border-box;
    position: relative;
    font-size: 14px;
    line-height: 24px;
    padding: 10px 0;
    border-bottom: solid 1px rgba(230, 42, 32, 0.42);
    font-weight: bold;
    transition: opacity linear 1s;
}
.details-row:first-child{
    border-top: solid 1px rgba(230, 42, 32, 0.42);
}
.details-row > div{
    vertical-align: top;
    display: inline-block;
}

.details-row:nth-child(even) {
    background: #ffffff1c;
}
.details-row:nth-child(odd) {
    background: #e62a201c;
}

.details-row > .details-row-user {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 30%;
    box-sizing: border-box;
    padding-left: 3px;
}
.details-row > .details-row-content {
    padding-left:30px;
    box-sizing: border-box;
    /*text-overflow: ellipsis;*/
    /*white-space: nowrap;*/
    overflow: hidden;
    width: 70%;
}
