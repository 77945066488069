.post-box-container {
    position: absolute;
    width: 45%;
    max-width: 400px;
    word-break: break-all;
    background: white;
    font-size: 36px;
    padding: 10px;
    color: #e62a20;
    border: 2px solid #e62a20;
    transition: top 2s cubic-bezier(0, 0, 0.2, 1), left 2s cubic-bezier(0, 0, 0.2, 1), width 1s cubic-bezier(0, 0, 0.2, 1);
    transform: translate(-50%, -50%);
}

/*.post-box-container-active {*/
    /*box-shadow: rgba(230, 42, 32, 0.8) 1px 3px 10px;*/
    /*z-index: 100000;*/
/*}*/

.post-box-header {
    height: 40px;
    line-height: 40px;
    font-size: 20px;
    width: 100%;
}

.post-box-head-image {
    background-size: 40px 40px;
    background-repeat: no-repeat;
    background-position: top left;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 20px;
    display: inline-block;
    background-color: #e62a20;
    vertical-align: top;
}

.post-box-nickname {
    vertical-align: top;
    line-height: 40px;
    display: inline-block;
    padding-left: 6px;
    max-width: calc(100% - 100px);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.post-box-content {
    padding: 20px 0;
}

.post-box-footer {
    text-align: right;
}

.post-box-point {
    display: inline-block;
    padding-left: 29px;
    margin-right: 10px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left center;
    height: 20px;
    line-height: 20px;
    font-size: 16px;
    font-weight: lighter;
}
.post-box-comment {
    display: inline-block;
    padding-left: 25px;
    margin-right: 10px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left center;
    height: 20px;
    font-size: 16px;
    line-height: 20px;
    font-weight: lighter;
}

.post-box-qrcode {
    width: 60px;
    height: 60px;
    position: absolute;
    cursor: pointer;
    top: 10px;
    right: 10px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}
