.home-posts-container {
    width: 100%;
    height: 100%;
}

.posts-content-container {
    width: 100%;
    height: 100%;
    overflow: auto;
}
.posts-content-container:after{
    content: '';
    display: block;
    width:100%;
    height: 100px;
}

.home-posts-refresh {
    position: absolute;
    top: 10px;
    left: 20px;
    width: 20px;
    height: 20px;
    z-index: 1000000;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.home-posts-header {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    z-index: 1000000;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.home-posts-back {
    position: absolute;
    top: 10px;
    left: 20px;
    width: 24px;
    height: 20px;
    z-index: 1000000;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.home-posts-home {
    position: absolute;
    top: 8px;
    left: 55px;
    width: 24px;
    height: 24px;
    z-index: 1000000;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
